import React from 'react';
import { Icon } from '@/components';
import type { AuthorizedModuleType } from '@/layout';
import type { MenuDataItem } from '@/const/route.config';

import './index.less';

interface AuthContainerProps {
  children: React.ReactNode;
  matchMenu: MenuDataItem[];
  authModule?: AuthorizedModuleType[];
}

const AuthContainer: React.FC<AuthContainerProps> = ({ children, authModule, matchMenu }) => {
  const firstMenuItem = matchMenu[0];
  const { moduleId } = JSON.parse(decodeURIComponent(firstMenuItem?.props || '{}'));
  const hasModuleAuth = authModule?.some((item) => item.moduleId === moduleId && item.status === 1);

  if (moduleId && hasModuleAuth === false) {
    return (
      <div className="fle-no-module-auth-container">
        <Icon type="icon-jiaosequanxian" />
        <span className="lock-text">您没有权限访问此模块，请联系管理员开通！</span>
      </div>
    );
  }

  return children;
};
export default AuthContainer;
