import qs, { ParsedQs } from 'qs';
import { MenuDataItem } from '@/const/route.config';

/**
 * 获取路径query参数
 */
export const getQuery = (): ParsedQs => qs.parse(window.location.search.substring(1));

/**
 * 金额格式化
 * @param data
 */
type moneyType = number | string;

interface ShowMoneyType {
  (data: moneyType): string;
}
export const showMoney: ShowMoneyType = (data) => (+data / 100).toFixed(2);

export function getMainDomain(): string {
  const { hostname } = window.location;
  const parts = hostname.split('.').reverse();

  if (parts && parts[1]) {
    return `${parts[1]}.${parts[0]}`;
  }

  return hostname;
}

export function clearMenuItem(menusData: MenuDataItem[]): MenuDataItem[] {
  const filterData = [...menusData];

  if (filterData[0]?.type === 'divider') {
    filterData.splice(0, 1);
  }
  return filterData
    .map((item) => {
      const children: MenuDataItem[] = item.routes || [];
      const finalItem = { ...item };

      if (!finalItem.name || finalItem.hideInMenu) {
        return null;
      }
      if (finalItem && finalItem?.routes) {
        if (
          !finalItem.hideChildrenInMenu &&
          children.some((child) => child && child.name && !child.hideInMenu)
        ) {
          return {
            ...item,
            routes: clearMenuItem(children),
          };
        }
        // routes 为空就直接删掉
        delete finalItem.routes;
      }
      return finalItem;
    })
    .filter((item) => item) as MenuDataItem[];
}

export function getDateNew(): string {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const seconds = String(currentDate.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
