import { makeAutoObservable, runInAction } from 'mobx';
import request from '@/tools/httpClient';
import { common } from '@/api';
import type { MenuDataItem } from '@/const/route.config';
import { getApplicationUrl, TokenKey } from '@/const/serviceEnv';
import type { StateProps } from '@/types/common/store';
import Cookies from 'js-cookie';
import { PLATFORM_TYPES, POSITIONS, RELATION_TYPES } from '@/const/csc';

const LiguanjiaMemberUrl = getApplicationUrl('liguanjia');

class CommonStore {
  [key: string]: any;

  singleHref = '';
  currentMenu: MenuDataItem | null = null; // 当前菜单
  csInfo: {
    csRight?: boolean;
    isAdmin?: boolean;
    hasSeat?: boolean;
    unreadMessages?: number;
    seatStatus?: number;
  } = {};
  csShowInfo: {
    show?: boolean;
    csNo?: string;
  } = {};
  memberToken = '';

  constructor() {
    makeAutoObservable(this);
  }

  // 获取消息列表
  remindGetListByPage = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.remindGetListByPage, params).then(({ data, success }) => {
          if (success) {
            resolve(data);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 统计消息数量
  remindCountNum = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.remindCountNum, params).then(({ data, success }) => {
          if (success) {
            resolve(data);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 消息设为已读
  remindRead = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.remindRead, params).then(({ data, success }) => {
          if (success) {
            resolve(true);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取验证码（通用）
  getSmsCode = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.smsCode, params).then(({ data, success }) => {
          if (success) {
            resolve(true);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取角标
  tabStatistical = (params: any) => {
    return new Promise((resolve, reject) => {
      try {
        request.post(common.tabStatistical, params).then(({ data, success }) => {
          if (success) {
            resolve(data);
          }
          reject();
        });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取行业列表
  getCompanyIndustryList = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        request
          .get(common.industryList)
          .then(({ data, success }) => {
            if (success && data) {
              resolve(data);
            }
            reject();
          })
          .catch((err) => {
            reject(err);
          });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取猜你喜欢
  getGuessList = async (params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        request
          .get(common.getGuessList, { params })
          .then(({ data, success }) => {
            if (success && data) {
              resolve(data);
            }
            reject();
          })
          .catch((err) => {
            reject(err);
          });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取客服权限信息
  getCsPermissionInfo = async (): Promise<any> => {
    request
      .post(common.csPermission, {}, { headers: { platform_type: PLATFORM_TYPES.carpunion_cs } })
      .then(({ data, success }) => {
        if (success && data) {
          this.updateState({
            csInfo: data || {},
          });
        } else {
          this.updateState({ csInfo: {} });
        }
      })
      .catch(() => {
        this.updateState({ csInfo: {} });
      });
  };
  // 获取咨询客服权限信息
  getCsShowInfo = async (relationId: any): Promise<any> => {
    request
      .post(
        common.csShow,
        {
          relationId,
          relationType: RELATION_TYPES.carpdancePlatform,
          position: POSITIONS.carpdancePlatform,
        },
        { headers: { platform_type: PLATFORM_TYPES.carpdance_user } },
      )
      .then(({ data, success }) => {
        if (success && data) {
          this.updateState({
            csShowInfo: data || {},
          });
        } else {
          this.updateState({ csShowInfo: {} });
        }
      })
      .catch(() => {
        this.updateState({ csShowInfo: {} });
      });
  };

  /**
   * 员工授权模块明细
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  getAuthModule = async (params: any) => {
    const res = await request.get(common.getAuthModule, { params });
    return res.data;
  };

  // 获取相似品列表
  getMatchList = async (token: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        request
          .get(common.matchList, {
            baseURL: LiguanjiaMemberUrl,
            headers: {
              Authorization: token,
            },
          })
          .then(({ data, status }) => {
            if (status == 1 && data) {
              resolve(data);
            }
            reject();
          })
          .catch((err) => {
            reject(err);
          });
      } catch (ex) {
        reject();
      }
    });
  };

  getTeamEquitiesExt = async (token: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        request
          .get('/v2/team/team/getTeamEquitiesExt', {
            baseURL: LiguanjiaMemberUrl,
            headers: {
              Authorization: token,
            },
          })
          .then(({ data, status }) => {
            if (status == 1 && data) {
              resolve(data);
            }
            reject();
          })
          .catch((err) => {
            reject(err);
          });
      } catch (ex) {
        reject();
      }
    });
  };

  // 获取相似品列表
  showMatchList = async (token: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        request
          .get(common.showMatchList, {
            baseURL: LiguanjiaMemberUrl,
            headers: {
              Authorization: token,
            },
          })
          .then(({ data, status }) => {
            if (status == 1 && data) {
              resolve(data);
            }
            reject();
          })
          .catch((err) => {
            reject(err);
          });
      } catch (ex) {
        reject();
      }
    });
  };

  productReplace = async (token: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        request
          .post(common.product_replace, params, {
            baseURL: LiguanjiaMemberUrl,
            headers: {
              Authorization: token,
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(({ data, status }) => {
            if (status == 1 && data) {
              resolve(data);
            }
            reject();
          })
          .catch((err) => {
            reject(err);
          });
      } catch (ex) {
        reject();
      }
    });
  };

  updateState = (params: StateProps) => {
    for (const [key, value] of Object.entries(params)) {
      runInAction(() => {
        this[key] = value;
      });
    }
  };
}

export default new CommonStore();
