import React, { useEffect, useState } from 'react';
import { Badge, Drawer, Tabs, TabsProps, message } from 'antd';
import NP from 'number-precision';

import { Icon } from '@/components';
import { CommonStore } from '@/stores';
import MessageBox from './MessageBox';

import './index.less';

interface NoticeDrawerProps {
  open: boolean;
  setMessageOpen: Function;
  setMessageNum: Function;
}
const NoticeDrawer: React.FC<NoticeDrawerProps> = ({ open, setMessageOpen, setMessageNum }) => {
  const [tabType, setTabType] = useState<string>('0');
  const [messageType, setMessageType] = useState<string>('0');
  const [businessNum, setBusinessNum] = useState<number | null>(null);
  const [systemNum, setSystemNum] = useState<number | null>(null);
  const onChange = (key: string) => {
    setMessageType(key);
  };
  useEffect(() => {
    if (open) {
      setMessageType('10');
      getCountNum();
    }
  }, [open]);
  const items: TabsProps['items'] = [
    {
      key: '10',
      label: (
        <Badge size="small" offset={[8, -5]} count={systemNum}>
          系统通知
        </Badge>
      ),
    },
    {
      key: '20',
      label: (
        <Badge size="small" offset={[8, -5]} count={businessNum}>
          业务通知
        </Badge>
      ),
    },
  ];
  const allRead = async () => {
    const success = await CommonStore.remindRead({});
    if (success) {
      message.success('操作成功');
      setMessageType('0');
      setTabType('0');
      setTimeout(() => {
        setMessageType('10');
      }, 100);
      getCountNum();
    }
  };
  // 获取数量
  const getCountNum = async () => {
    const param = {
      channelType: 10,
      isRead: -10, // 未读数量
    };
    const data: any = await CommonStore.remindCountNum(param);
    setSystemNum(data?.systemNum);
    setBusinessNum(data?.businessNum);
    setMessageNum(NP.plus(data?.systemNum, data?.businessNum));
  };

  const handleClickOutside = (event: MouseEvent) => {
    const drawerDOM = document.querySelector('.message-drawer-root');
    if (drawerDOM && !drawerDOM.contains(event.target as Node) && setMessageOpen) {
      setMessageOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    if (!open) document.removeEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  return (
    <Drawer
      destroyOnClose
      width={544}
      title="消息中心"
      open={open}
      placement="left"
      rootClassName="message-drawer-root"
      onClose={() => {
        setMessageOpen(false);
        setMessageType('0');
      }}
    >
      <Tabs className="tab-class" activeKey={messageType} items={items} onChange={onChange} />
      <div className="has-read-box" onClick={allRead}>
        {' '}
        <Icon type="icon-yidu" /> 全部标记为已读
      </div>
      <div className="message-tab-box">
        <div
          className={tabType === '0' ? 'message-tab message-tab-active' : 'message-tab'}
          onClick={() => {
            setTabType('0');
          }}
        >
          全部消息
        </div>
        <div
          className={tabType === '-10' ? 'message-tab message-tab-active' : 'message-tab'}
          onClick={() => {
            setTabType('-10');
          }}
        >
          仅展示未读
        </div>
        <div
          className={tabType === '10' ? 'message-tab message-tab-active' : 'message-tab'}
          onClick={() => {
            setTabType('10');
          }}
        >
          仅展示已读
        </div>
      </div>
      {messageType === '10' && (
        <MessageBox messageType="10" tabType={tabType} setMessageOpen={setMessageOpen} />
      )}
      {messageType === '20' && (
        <MessageBox messageType="20" tabType={tabType} setMessageOpen={setMessageOpen} />
      )}
    </Drawer>
  );
};
export default React.memo(NoticeDrawer);
