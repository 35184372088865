import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { Icon } from '@/components';
import { CommonStore } from '@/stores';
import { Scrollbars } from 'react-custom-scrollbars';
import { getApplicationUrl } from '@/const/serviceEnv';
import { observer } from 'mobx-react';
import Draggable from 'react-draggable';
import { PLATFORM_TYPES, RELATION_TYPES } from '@/const/csc';
import Cookies from 'js-cookie';
import { UserInfo } from '@/stores/account';
import { message } from '../Message';
import './index.less';

const HelpCenterUrl = getApplicationUrl('help');

// 悬浮按钮组件
const FloatingButton = (props: { userInfo?: UserInfo }) => {
  const { userInfo } = props;
  const { csShowInfo } = CommonStore;
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [guessList, setGuessList] = useState([]);
  const [helpVisible, setHelpVisible] = useState<string>(
    window.sessionStorage.getItem('helpVisible') || '1',
  );
  const [isClick, setIsClick] = useState(false);
  const clickThreshold = 5;

  const handleDragStop = (_: any, data: any) => {
    const { x, y } = data;

    // 判断点击事件
    if (
      Math.abs(x - dragOffset.x) < clickThreshold &&
      Math.abs(y - dragOffset.y) < clickThreshold
    ) {
      setIsClick(true);
    }

    setDragOffset({ x, y });
  };
  // 拖动end

  const getMenuDetails = () => {
    const menuMap: any = {
      智鲤: '1',
      万鲤商联: '2',
    };

    if (
      !CommonStore ||
      !CommonStore.currentMenu ||
      (CommonStore.currentMenu as unknown) === '/404'
    ) {
      return { appPath: '', scope: '' };
    } else {
      const menuItems = (CommonStore.currentMenu?.layout_names || '')
        .split('-')
        .filter((item: any) => item.trim() !== '');
      if (menuItems.length === 0) {
        return { appPath: '', scope: '' };
      }
      const appPath = menuItems.join('-');
      const firstMenu = menuItems[0];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const scope = menuMap[firstMenu] || '';

      return { appPath };
    }
  };

  const getGuessList = async () => {
    const params = getMenuDetails();
    if (!params.appPath) return;
    const data = await CommonStore.getGuessList(params);
    if (data) {
      setGuessList(data);
    }
  };

  const renderGuessList = () => {
    return guessList.map((item: { id: string; title: string }) => {
      return (
        <div
          key={item.id}
          onClick={() => handleGuessItemClick(item)}
          className="floating-help-item"
        >
          {item.title}
        </div>
      );
    });
  };

  const handleImgClick = () => {
    if (!isClick) return;
    setHelpVisible('2');
  };

  // to帮助详情
  const handleGuessItemClick = (item: { id: string; title: string }) => {
    if (!isClick) return;
    window.open(`${HelpCenterUrl}/article/details/${item.id}`, '_blank');
  };
  // to帮助中心
  const handleToHelpCenter = () => {
    if (!isClick) return;
    window.open(`${HelpCenterUrl}/home`, '_blank');
  };
  // to帮助中心文章
  const handleToHelpList = () => {
    if (!isClick) return;
    window.open(`${HelpCenterUrl}/article/list`, '_blank');
  };

  // 客服
  const handleKFClick = () => {
    if (!userInfo?.id) return message.warning('缺少用户ID');

    const params: any = {
      relationId: userInfo.id,
      relationType: RELATION_TYPES.carpdancePlatform,
      platform_type: PLATFORM_TYPES.carpdance_user,
      clientType: '16',
      csNo: csShowInfo.csNo || '',
      token: Cookies.get('token') || '',
      sourceUrl: encodeURIComponent(window.location.origin + window.location.pathname),
      sourceType: '万鲤商联会员端',
    };

    const paramsStr = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
    const cscUserUrl = getApplicationUrl('cscUser');
    const cscOpenUrl = `${cscUserUrl}?${paramsStr}`;

    window.open(cscOpenUrl, 'CscWindow');
  };

  useEffect(() => {
    getGuessList();
  }, [CommonStore.currentMenu]);

  useEffect(() => {
    window.sessionStorage.setItem('helpVisible', helpVisible);
    if (helpVisible === '2' && guessList.length === 0) {
      getGuessList();
    }
  }, [helpVisible]);

  return (
    <Draggable
      handle=".floating-button-container"
      grid={[1, 1]} // 可选的网格设置
      scale={1} // 可选的缩放设置
      onStart={() => {
        setIsClick(false);
      }} // 拖动开始时执行的函数
      //   onDrag={handleDrag} // 拖动过程中执行的函数
      onStop={handleDragStop}
    >
      <div className="floating-button-container">
        {/* 悬浮按钮 */}
        {helpVisible === '1' && (
          <div className="floating-button">
            <img
              className="floating-button-header"
              draggable="false"
              onClick={handleImgClick}
              src="https://oss.elebuys.com/tmpdir/202311171111480003742274.png"
            />
            <div className="floating-button-items-wrapper">
              {csShowInfo.show && (
                <Tooltip placement="left" title={'咨询客服'}>
                  <div className="floating-button-item" onClick={handleKFClick}>
                    <Icon type="icon-fuchuang-kefu" />
                  </div>
                </Tooltip>
              )}
              <Tooltip placement="left" title={'帮助中心'}>
                <div className="floating-button-item" onClick={handleToHelpCenter}>
                  <Icon type="icon-fuchuang-bangzhuzhongxin" />
                </div>
              </Tooltip>
            </div>
          </div>
        )}
        {/* 猜你想问 */}
        {helpVisible === '2' && (
          <div className="floating-help-wrapper">
            <img
              className="floating-help-header"
              draggable="false"
              src="https://oss.elebuys.com/tmpdir/202311171111480003742274.png"
            />
            <Icon
              onClick={() => {
                if (!isClick) return;
                setHelpVisible('1');
              }}
              className="floating-help-close"
              type="icon-suoxiao"
            />
            <div className="floating-help-main">
              <div className="floating-help-title" onClick={handleToHelpList}>
                <div className="left">
                  <Icon type="icon-fuchuang-zhong-cainixiangwen" />
                  <div className="floating-help-title-text">猜你想问</div>
                </div>
                <Icon type="icon-arrowright" />
              </div>
              <div className="floating-help-items">
                <Scrollbars
                  style={{ width: 126, height: 294 }} // 设置滚动条容器的样式
                  autoHide // 是否自动隐藏滚动条
                  autoHideTimeout={1000} // 自动隐藏的延迟时间
                  autoHideDuration={200} // 自动隐藏的动画持续时间
                  universal // 是否支持平台通用的滚动行为
                >
                  {renderGuessList()}
                </Scrollbars>
              </div>
            </div>
          </div>
        )}
      </div>
    </Draggable>
  );
};

export default observer(FloatingButton);
