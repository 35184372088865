import React from 'react';
import { Button, Result } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import { PageCacheStore } from '@/stores';

import './index.less';

interface ErrorPageProps {
  status?: 'error' | '404' | 'noAuth';
}

interface ErrorConfigTypes {
  src: string;
  subTitle: string;
  actions: Array<'home' | 'refresh'>;
}

const ErrorPage: React.FC<ErrorPageProps> = ({ status = 'error' }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const errorConfig = new Map<ErrorPageProps['status'], ErrorConfigTypes>([
    [
      '404',
      {
        src: 'https://oss.elebuys.com/tmpdir/202308111113450002128358.png',
        subTitle: '抱歉，找不到您访问的页面',
        actions: ['home'],
      },
    ],
    [
      'noAuth',
      {
        src: 'https://oss.elebuys.com/resource/fle-ui/202112131422000003660173.png',
        subTitle: '您没有权限访问此页面，请联系管理员授权',
        actions: ['home'],
      },
    ],
    [
      'error',
      {
        src: 'https://oss.elebuys.com/resource/fle-ui/202112131419170003660172.png',
        subTitle: '抱歉，出了一点意外，请您尝试刷新...',
        actions: ['home', 'refresh'],
      },
    ],
  ]);

  return (
    <div className="fx__error-page">
      <Result
        icon={<></>}
        title={<img style={{ width: 400 }} src={errorConfig.get(status)?.src || ''} />}
        subTitle={
          <div style={{ paddingTop: 50, fontSize: 18 }}>{errorConfig.get(status)?.subTitle}</div>
        }
        extra={errorConfig.get(status)?.actions.map((action) => {
          if (action === 'home') {
            return (
              <Button
                key={action}
                type="primary"
                onClick={() => navigate('/module/workbench/dashboard')}
              >
                返回首页
              </Button>
            );
          }
          if (action === 'refresh') {
            return (
              <Button
                key={action}
                type="primary"
                ghost
                onClick={() =>
                  PageCacheStore.refreshPage(`${location.pathname}${location.search}`).then(
                    (path) => {
                      path && navigate('/redirectPage', { state: { path }, replace: true });
                    },
                  )
                }
              >
                点我刷新
              </Button>
            );
          }

          return null;
        })}
      />
    </div>
  );
};

export default ErrorPage;
